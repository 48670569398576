<!--
 * @ Author: Lmi
 * @ Create Time: 2023-09-05 15:00:55
 * @ Modified by: Your name
 * @ Modified time: 2023-09-05 15:00:55
 * @ Description: 数据统计>平台统计>按月总用电量统计
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="consumer-charging-statistics">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { offlineExport, code } from '@/utils';
  import { dataStatisticsServe, barrierPage } from '@/service/apis';

  export default {
    name: 'monthlyElectricityConsumptionStatisticsList',

    data() {
      return {
        pageButtons: [
          {
            id: '0',
            text: '导出',
            type: 'plain',
            enabled: () => code('monthly:electricity:consumption:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '电站名称', prop: 'stationName', minWidth: '180px' },
          { label: '1月', prop: 'january', minWidth: '120px' },
          { label: '2月', prop: 'february', minWidth: '120px' },
          { label: '3月', prop: 'march', minWidth: '120px' },
          { label: '4月', prop: 'april', minWidth: '120px' },
          { label: '5月', prop: 'may', minWidth: '120px' },
          { label: '6月', prop: 'june', minWidth: '120px' },
          { label: '7月', prop: 'july', minWidth: '120px' },
          { label: '8月', prop: 'august', minWidth: '120px' },
          { label: '9月', prop: 'september', minWidth: '120px' },
          { label: '10月', prop: 'october', minWidth: '120px' },
          { label: '11月', prop: 'november', minWidth: '120px' },
          { label: '12月', prop: 'december', minWidth: '120px' },
        ],
        tableTitle: '明细列表',
        pageInfo: {
          current: 1,
          size: 10,
        },
        stationData: [], // 电站数据
        searchParams: {
          queryDate: '',
          stationId: '',
          type: 1,
        },
      };
    },
    created() {
      const date = new Date();
      this.searchParams.queryDate = `${date.getFullYear()}`; // 默认当前年份
      console.log('---queryDate', this.searchParams.queryDate);
      this.getStationData();
      this.searchTableList();
    },
    computed: {
      searchData() {
        return [
          {
            label: '时间维度',
            key: 'queryDate',
            comName: 'YkcDatePicker',
            format: 'yyyy',
            type: 'year',
          },
          {
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationData,
            remote: true,
          },
        ];
      },
    },
    methods: {
      /**
       * @desc 获取电站数据
       * */
      getStationData() {
        barrierPage.findStationList({}).then(res => {
          this.stationData = (res || []).map(item => {
            return {
              id: item.stationId,
              name: item.stationName,
            };
          });
        });
      },

      /** 根据条件查询数据 */
      searchTableList() {
        dataStatisticsServe
          .totalMonthList({
            ...this.pageInfo,
            ...this.searchParams,
          })
          .then(res => {
            this.tableData = res.records;
            this.pageInfo.total = res.total || 0;
          });
      },
      /**
       * @desc 导出文件 - 按月总用电量统计列表
       * */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'month_info_statistics',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        const date = new Date();
        // this.searchParams.queryDate = `${date.getFullYear()}`; // 默认当前年份
        // console.log('---queryDate', this.searchParams.queryDate);
        Object.assign(this.searchParams, form, {
          type: 1,
          queryDate: `${date.getFullYear()}`,
        });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
